import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import PreviewCompatibleImage from "../components/PreviewCompatibleImage"
import BlogSidebar from "./blog-sidebar"

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Content, { HTMLContent } from "../components/Content"

import "./article-post-styles.scss"

const ArticlePreFooter = ({ context }) => {
  const { nextArticle } = context;

  return (
    <section className="article-pre-footer mt-8">
      {nextArticle.isGuide && !!nextArticle.slug ? (
        <div
          className="row py-5 flex-column flex-md-row justify-content-between align-items-md-center border-top border-bottom">
          <div
            className="col-12 col-md-8 pr-lg-8 mb-2">
            <div className="d-flex flex-column justify-content-center">
              <div className="font-size-lg-2 text-uppercase font-medium">Next</div>
              <Link to={nextArticle.slug} className="mb-2 text-black">
                {nextArticle.title}
              </Link>
            </div>
          </div>
          <div className="col-8 col-md-4 col-lg-3">
            <img src={nextArticle.featuredimage.publicURL} alt="" />
          </div>
        </div>
      ) : ''}
    </section>
  )
}

export const ArticlePostTemplate = ({
  context,
  tableOfContents,
  content,
  contentComponent,
  title,
  guideMainLink,
  guideMainTitle,
  author,
  postDate,
  timeToRead,
  imageOverlay = "/img/blog-page-overlay.png",
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div>
      <section className="section-bg position-relative">
        <PreviewCompatibleImage
          src={imageOverlay}
          className="position-absolute bg-overlay"
          style={{ height: "100%" }}
        />
        <div className="container">
          <div className="row flex-column align-items-center">
            <div className="col-lg-10 col-xl-12">
              <h1 className="text-left text-white mb-5">{title}</h1>
            </div>
            <div className="col-lg-12">
              <div className="d-flex align-items-center mb-4">
                <img src={author.authorImage.publicURL} width="80" alt="" />
                <div className="d-flex flex-column align-items-start text-white ml-4">
                  <p className="m-0 text-center">By {author.authorName}</p>
                  <p className="m-0 text-center">{postDate} - {timeToRead} min read</p>
                </div>
              </div>
              <div className="ml-0 ml-md-7 text-white">
                <div className="d-flex align-items-center ml-0 ml-md-4">
                  <p className="pr-2 mb-0 mr-2 border-right border-white leading-none">Guide</p>
                  <Link className="leading-none text-white font-weight-bold" to={guideMainLink}>{guideMainTitle}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog-main">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-8 blog-main-inner py-7">
              <PostContent content={content} />
              <ArticlePreFooter context={context} />
            </div>
            <BlogSidebar content={tableOfContents} />
          </div>
        </div>
      </section>
    </div>
  )
}

ArticlePostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  context: PropTypes.object.isRequired
}

const ArticlePost = ({ data, path, pageContext }) => {
  const { markdownRemark: post, imageOverlay, authorList } = data

  const author = authorList.edges[0].node.frontmatter.authorList.find(author => {
    return author.authorName === post.frontmatter.author
  })

  return (
    <Layout loadMap={false} hasProgressBar={true}>
      <SEO
        path={path}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.featuredimage.publicURL}
      />
      <ArticlePostTemplate
        context={pageContext}
        tableOfContents={post.tableOfContents}
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        title={post.frontmatter.title}
        guideMainLink={post.frontmatter.guideMainLink}
        guideMainTitle={post.frontmatter.guideMainTitle}
        author={author}
        postDate={post.frontmatter.date}
        timeToRead={post.timeToRead}
        imageOverlay={imageOverlay}
      />
    </Layout>
  )
}

ArticlePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ArticlePost

export const pageQuery = graphql`
  query ArticlePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      timeToRead
      html
      tableOfContents (
        maxDepth: 2
      )
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        title
        author
        guideMainLink
        guideMainTitle
        description
        featuredimage {
          publicURL
        }
      }
    }
    imageOverlay: file(relativePath: { eq: "blog-page-overlay.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    authorList: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "author-list" } } }
    ) {
      edges {
        node {
          frontmatter {
            authorList {
              authorName
              authorImage {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`
